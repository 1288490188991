import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	// Medicines
	async getAll() {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.medicine.get, params)

		return data
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.medicine.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.medicine.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.medicine.update}/${id}`, data)
	},
	// Colors medicine
	async getAllColor() {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.medicineColor.get, params)

		return data
	},
	saveColor(id, data) {
		return useJwt.put(`${API_ROUTES.medicineColor.save}/${id}`, data)
	},
	// Presentation medicine
	async getAllPresentation() {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.medicinePresentation.get, params)

		return data
	},
}
